import PropTypes from 'prop-types';
// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import { Icon } from '@iconify/react';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import UserQuickEditForm from './user-quick-edit-form';
import ConflictTableEditForm from '../conflictTable/conflict-table-edit-form';

// ----------------------------------------------------------------------

export default function UserTableRow({ row, selected, onEditRow, onSelectRow, onDeleteRow }) {
  const { userName, avatarUrl, company, role, status, email, fullName, phoneNumber } = row;

  console.log(row);

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  const formattedDate = (rawDate) => {
    const date = new Date(rawDate);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('tr-TR', options); // tr-TR Türkçe formatında tarih gösterimi
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell sx={{ whiteSpace: 'countryDescription' }}>{row.countryDescription}</TableCell>

        <TableCell sx={{ whiteSpace: 'subjectDescription' }}>{row.subjectDescription}</TableCell>

        <TableCell sx={{ whiteSpace: 'year' }}>{row.year}</TableCell>

        <TableCell sx={{ whiteSpace: 'existingSourceDataDescription' }}>
          {row.existingSourceDataDescription}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'existingValue' }}>{row.existingValue}</TableCell>

        <TableCell sx={{ whiteSpace: 'incomingSourceDataDescription' }}>
          {row.incomingSourceDataDescription}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'incomingValue' }}>{row.incomingValue}</TableCell>

        <TableCell>
          <Label variant="soft" color={row.isResulted ? 'success' : 'warning'}>
            {row.isResulted ? 'success' : 'waiting for approval'}
          </Label>
        </TableCell>
        <TableCell sx={{ whiteSpace: 'createdDate' }}>{formattedDate(row.createdDate)}</TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <Tooltip title="Quick Edit" placement="top" arrow>
            <IconButton color={quickEdit.value ? 'inherit' : 'default'} onClick={quickEdit.onTrue}>
              <Icon icon="material-symbols-light:order-approve" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      {/* <UserQuickEditForm currentUser={row} open={quickEdit.value} onClose={quickEdit.onFalse} /> */}
      <ConflictTableEditForm
        currentUser={row}
        countryDescription={row.countryDescription}
        subjectDescription={row.subjectDescription}
        year={row.year}
        id={row.id}
        existingSourceDataDescription={row.existingSourceDataDescription}
        existingValue={row.existingValue} // Bu satır existingValue'yi prop olarak set ediyor
        incomingSourceDataDescription={row.incomingSourceDataDescription}
        incomingValue={row.incomingValue}
        isResulted={row.isResulted}
        open={quickEdit.value}
        onClose={quickEdit.onFalse}
      />
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
