import { Helmet } from 'react-helmet-async';
import { PopulationListView } from 'src/sections/population/view';
// sections


// ----------------------------------------------------------------------

export default function PopulationListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Population List</title>
      </Helmet>

      <PopulationListView />
    </>
  );
}
