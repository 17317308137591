import { _mock } from "./_mock";

export const USERS_STATUS_OPTIONS = [
  { value: 'active', label: 'Active' },
  { value: 'pending', label: 'Pending' },
  { value: 'banned', label: 'Banned' },
  { value: 'rejected', label: 'Rejected' },
];

export const _metaDataTypes = [...Array(17)].map((_, index) => ({
    id: _mock.id(index),
    zipCode: '85807',
    state: 'Virginia',
    city: 'Rancho Cordova',
    // profileRecords:_mock.profileRecords(index),
    // metaDataType:_mock.metaDataType(index),
    address: '908 Jack Locks',
    explanatio: _mock.explanatio(index),
    country: _mock.country(index),
    mostrecentyear: _mock.mostrecentyear(index),
    mostrecentvalue: _mock.mostrecentvalue(index),
    isVerified: _mock.boolean(index),
    company: _mock.companyName(index),
    avatarUrl: _mock.image.avatar(index),
    creationDate: _mock.creationDate(index),
    status:
      (index === 2 && 'onay bekliyor') || (index === 3 && 'engellendi') || (index === 4 && 'pasif') || 'aktif',
  }));