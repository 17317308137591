import PropTypes from 'prop-types';
import { useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------
export default function UserTableToolbar({
  filters,
  onFilters,
  //
  roleOptions,
  subjectDescriptions,
  countryDescriptions,
  ıncomingSoutrceDataDescriptions,
  isResulteds,
}) {
  const popover = usePopover();

  const handleFilterName = useCallback(
    (event) => {
      onFilters('fullName', event.target.value);
    },
    [onFilters]
  );

  // const handleFilterRole = useCallback(
  //   (event) => {
  //     onFilters(
  //       'role',
  //       typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
  //     );
  //   },
  //   [onFilters]
  // );

  const handleFilterSubject = useCallback(
    (event) => {
      onFilters(
        'subjectDescription',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterCountry = useCallback(
    (event) => {
      onFilters(
        'countryDescription',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterIncomingSourceDataDescription = useCallback(
    (event) => {
      onFilters(
        'incomingSourceDataDescription',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  const handleFilterStatus = useCallback(
    (event) => {
      onFilters(
        'isResulted',
        typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
      );
    },
    [onFilters]
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        {/* <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>Rol</InputLabel>

          <Select
            multiple
            value={filters.role}
            onChange={handleFilterRole}
            input={<OutlinedInput label="Role" />}
            renderValue={(selected) => selected.map((value) => value).join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {roleOptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox disableRipple size="small" checked={filters.role.includes(option)} />
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 360 },
          }}
        >
          <InputLabel>Subject</InputLabel>

          <Select
            multiple
            value={Array.isArray(filters.subjectDescription) ? filters.subjectDescription : []}
            onChange={handleFilterSubject}
            input={<OutlinedInput label="Subject" />}
            renderValue={(selected) => selected.map((value) => value).join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {subjectDescriptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox
                  disableRipple
                  size="small"
                  checked={(filters.subjectDescription || []).includes(option)}
                />

                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 360 },
          }}
        >
          <InputLabel>Country</InputLabel>

          <Select
            multiple
            value={Array.isArray(filters.countryDescription) ? filters.countryDescription : []}
            onChange={handleFilterCountry}
            input={<OutlinedInput label="Subject" />}
            renderValue={(selected) => selected.map((value) => value).join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {countryDescriptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox
                  disableRipple
                  size="small"
                  checked={(filters.countryDescription || []).includes(option)}
                />

                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 360 },
          }}
        >
          <InputLabel>Incoming Source Data</InputLabel>

          <Select
            multiple
            value={
              Array.isArray(filters.incomingSourceDataDescription)
                ? filters.incomingSourceDataDescription
                : []
            }
            onChange={handleFilterIncomingSourceDataDescription}
            input={<OutlinedInput label="Subject" />}
            renderValue={(selected) => selected.map((value) => value).join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {ıncomingSoutrceDataDescriptions.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox
                  disableRipple
                  size="small"
                  checked={(filters.incomingSourceDataDescription || []).includes(option)}
                />

                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 360 },
          }}
        >
          <InputLabel>Status</InputLabel>

          <Select
            multiple
            value={Array.isArray(filters.isResulted) ? filters.isResulted : []}
            onChange={handleFilterStatus}
            input={<OutlinedInput label="Subject" />}
            renderValue={(selected) => selected.map((value) => value).join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {isResulteds.map((option) => (
              <MenuItem key={option} value={option}>
                <Checkbox
                  disableRipple
                  size="small"
                  checked={(filters.isResulted || []).includes(option)}
                />

                {option ? 'Success' : 'Waiting For Approval'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  );
}

UserTableToolbar.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  roleOptions: PropTypes.array,
  subjectDescriptions: PropTypes.array,
  countryDescriptions: PropTypes.array,
  ıncomingSoutrceDataDescriptions: PropTypes.array,
  isResulteds: PropTypes.array,
};
