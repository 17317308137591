import { Helmet } from 'react-helmet-async';
import MaternalMortalityListView from 'src/sections/maternalmortality/view/maternal-mortality-view';
// sections


// ----------------------------------------------------------------------

export default function MaternalMortalityRateListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Maternal Mortality Rate ListPage List</title>
      </Helmet>

      <MaternalMortalityListView />
    </>
  );
}
