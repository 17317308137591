import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
// utils
import { fData } from 'src/utils/format-number';
// routes
import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hook';
// assets
import { countries } from 'src/assets/data';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
  RHFAutocomplete,
} from 'src/components/hook-form';
import { TextField } from '@mui/material';
import { SubjectGetData } from 'src/api/subject';
import { ManualEntry } from 'src/api/manualentry';
import { GetCountryListApiResponseData } from 'src/api/country';
import EcommerceYearlySales from 'src/sections/overview/e-commerce/ecommerce-yearly-sales';
import { CountryGetData } from 'src/api/country-data';
import AppAreaInstalled from 'src/sections/overview/app/app-area-installed';

// ----------------------------------------------------------------------

export default function ManualDataEntryView() {
  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    // name: Yup.string().required('Name is required'),
    // email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    // phoneNumber: Yup.string().required('Phone number is required'),
    // address: Yup.string().required('Address is required'),
    // country: Yup.string().required('Country is required'),
    // company: Yup.string().required('Company is required'),
    // state: Yup.string().required('State is required'),
    // city: Yup.string().required('City is required'),
    // role: Yup.string().required('Role is required'),
    // zipCode: Yup.string().required('Zip code is required'),
    // avatarUrl: Yup.mixed().nullable().required('Avatar is required'),
    // // not required
    // status: Yup.string(),
    // isVerified: Yup.boolean(),
  });

  ManualDataEntryView.propTypes = {
    // No need for currentUser prop types
  };

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues: {
      country: null,
      dataSubject: null,
      selectedChild: null,
      year: '',
      value: '',
      // Add other fields as needed
    },
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const { id } = useParams();

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      // API'ye isteği gönderin ve yanıtı alın
      const response = await ManualEntry(
        data.country.id,
        data.selectedChild.id,
        data.value,
        data.year
      );

      if (response.message === 'Create success!') {
        // variant = 'succes'; // Mesaj belirli bir şarta uyuyorsa variant'ı error yap
        enqueueSnackbar({ message: response.message, variant: 'success' });
      } else {
        enqueueSnackbar({ message: response.message.message, variant: 'error' });
      }
      // Formu sıfırlayın
      reset({
        country: null,
        dataSubject: null,
        selectedChild: null,
        year: '',
        value: '',
      });

      // Chart verisini sıfırlayın
      setChartData(null);

      console.info('DATA', data);
    } catch (error) {
      console.error(error);
    }
  });

  const { countryListApiResponseData } = GetCountryListApiResponseData();
  const { SubjectData } = SubjectGetData();
  const selectedCountry = watch('country');
  const selectedChild = watch('selectedChild');
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (selectedCountry && selectedCountry.id) {
      console.log('Selected Country ID:', selectedCountry.id);
    }
    if (selectedChild && selectedChild.id) {
      console.log('Selected Child ID:', selectedChild.id);
    }
  }, [selectedCountry, selectedChild]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedCountry && selectedCountry.id && selectedChild && selectedChild.id) {
          const countryData = await CountryGetData(selectedCountry.id, selectedChild.id);
          console.log('Country Data:', countryData);

          // Extract year and value data for the chart
          const chartLabels = countryData[0].dataDetail.map((data) => data.year);
          const chartValues = countryData[0].dataDetail.map((data) => data.value);

          // Set up chart data
          const data = {
            categories: chartLabels,
            series: [
              {
                year: 'Population',
                data: chartValues,
              },
            ],
          };

          setChartData(data);
        }
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };

    fetchData();
  }, [selectedCountry, selectedChild]);
  console.log(chartData);
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ mb: 3 }}>
              Manual Entry
            </Typography>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFAutocomplete
                name="country"
                label="Country"
                options={countryListApiResponseData}
                getOptionLabel={(option) => option.description.toUpperCase()} // Convert to uppercase
                renderInput={(params) => <TextField {...params} label="Country" />}
              />

              <RHFAutocomplete
                name="dataSubject"
                label="Data Subject"
                options={SubjectData.slice(0, 5)} // Assuming you have subjectData for the initial descriptions
                getOptionLabel={(option) => option.description}
                renderInput={(params) => <TextField {...params} label="Data Subject" />}
                onChange={(e, selectedOption) => {
                  // Handle the change event to update the options for the second RHFAutocomplete
                  const selectedDescription = selectedOption?.description;
                  const selectedChilds = SubjectData.find(
                    (item) => item.description === selectedDescription
                  )?.childs;
                  setValue('selectedDataSubject', selectedDescription); // Assuming you have a form value for selectedDataSubject
                  setValue('selectedChilds', selectedChilds); // Assuming you have a form value for selectedChilds
                }}
              />

              {/* Second RHFAutocomplete for selecting "childs" based on the selected "description" */}
              <RHFAutocomplete
                name="selectedChild"
                label="Select Child"
                options={watch('selectedChilds') || []} // Options based on the selected "description"
                getOptionLabel={(option) => option.description}
                renderInput={(params) => <TextField {...params} label="Select Child" />}
                onChange={(e, selectedOption) => {
                  const selectedDescription = selectedOption?.description;
                  setValue('selectedChild', selectedOption); // Set the selected option directly
                  setValue('selectedChildDescription', selectedDescription); // Assuming you have a form value for the description
                }}
              />

              <RHFTextField name="year" label="Year" />

              <RHFTextField name="value" label="Value" />
            </Box>
            <Grid>
              {chartData && (
                <AppAreaInstalled
                  title="Country Dashboard"
                  subheader={
                    <Typography variant="subtitle2" sx={{ color: 'black' }}>
                      {`Selected Country: ${
                        selectedCountry?.description.toUpperCase() || 'All Countries'
                      }`}
                    </Typography>
                  }
                  chart={{
                    categories: chartData.categories,
                    series: [
                      {
                        year: 'All Years',
                        data: [
                          {
                            name: watch('selectedChild')?.description || 'All Children', // Use the selectedChild description as the name
                            data: chartData.series[0].data,
                          },
                        ],
                      },
                    ],
                  }}
                />
              )}
            </Grid>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Save
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
