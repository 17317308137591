import PropTypes from 'prop-types';
import { useEffect, useState, useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
// components
import Iconify from 'src/components/iconify';
import { Upload } from 'src/components/upload';
import { useParams } from 'src/routes/hook';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import Import from 'src/components/upload/ımport';

// ----------------------------------------------------------------------

export default function ImportManagerNewFolderDialog({
  title = 'Import Excel',
  open,
  onClose,
  //
  onCreate,
  onUpdate,
  //
  folderName,
  onChangeFolderName,
  ...other
}) {
  const [files, setFiles] = useState([]);

  const [loading, setLoading] = useState(false);

  console.log(files);

  useEffect(() => {
    if (!open) {
      setFiles([]);
    }
  }, [open]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const excelFiles = acceptedFiles.filter(
        (file) =>
          file.type === 'application/vnd.ms-excel' || // Excel 97-2003
          file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || // Excel 2007 ve sonrası
          file.name.endsWith('.xls') || // .xls uzantılı dosyalar
          file.name.endsWith('.xlsx') // .xlsx uzantılı dosyalar
      );

      const newFiles = excelFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles([...files, ...newFiles]);
    },
    [files]
  );

  const handleRemoveFile = (inputFile) => {
    const filtered = files.filter((file) => file !== inputFile);
    setFiles(filtered);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  const fileAccept = {
    'application/vnd.ms-excel': [],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  };

  const { id } = useParams();

  

  const handleUpload = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
  if (accessToken) {
    try {
      setLoading(true); // Set loading to true when starting the upload

      const subjectId = String(id);
      console.log(files);

      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append('filepatch', file);
      });
      
      const response = await axios.post(
        'https://backend.ocdt.berkuk.xyz/api/DataImport/ImportExcelData',
        formData,
        {
          params: {
            // subjectId: subjectId,
          },
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
          },
        }
      );

      console.log('API Response:', response);
      enqueueSnackbar('Create success!');
    } catch (error) {
      console.error('API Error:', error);
    } finally {
      setLoading(false); // Set loading back to false after the upload is completed (success or error)
    }

    onClose();
  };
  }
    

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}> {title} </DialogTitle>

      <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
        {(onCreate || onUpdate) && (
          <TextField
            fullWidth
            label="Folder name"
            value={folderName}
            onChange={onChangeFolderName}
            sx={{ mb: 3 }}
          />
        )}

        <Import
          multiple
          accept={fileAccept}
          files={files}
          onDrop={handleDrop}
          onRemove={handleRemoveFile}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          startIcon={<Iconify icon="eva:cloud-upload-fill" />}
          onClick={handleUpload}
          disabled={loading} // Disable the button while loading
        >
          {loading ? 'Uploading...' : 'Import'}
        </Button>

        {!!files.length && (
          <Button variant="outlined" color="inherit" onClick={handleRemoveAllFiles}>
            Delete
          </Button>
        )}

        {(onCreate || onUpdate) && (
          <Stack direction="row" justifyContent="flex-end" flexGrow={1}>
            <Button variant="soft" onClick={onCreate || onUpdate}>
              {onUpdate ? 'Save' : 'Create'}
            </Button>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
}

ImportManagerNewFolderDialog.propTypes = {
  folderName: PropTypes.string,
  onChangeFolderName: PropTypes.func,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
};
