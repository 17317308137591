import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import Grid from '@mui/system/Unstable_Grid/Grid';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';

// _mock
import { _userList, _roles, USER_STATUS_OPTIONS, _metaDataType } from 'src/_mock';
import { _metaDataTypes } from 'src/_mock/_metaData';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// api

// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';

import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
  TableSkeleton,
} from 'src/components/table';
import UserTableToolbarSimple from 'src/sections/user/user-table-toolbar-simple';
import UserTableFiltersResult from 'src/sections/user/user-table-filters-result';
import AppAreaInstalled from 'src/sections/overview/app/app-area-installed';
import ProfileTableRow from 'src/sections/user/profile-table-row';
import axios from 'axios';
import { endpoints } from 'src/utils/axios';
import { ParticipationGetData } from 'src/api/participation-data';

//

// ----------------------------------------------------------------------

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const defaultFilters = {
  fullName: '',
  role: [],
  status: 'all',
};

export default function ParticipationListView() {
  const table = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const { ParticipationData, ParticipationDataLoading } = ParticipationGetData();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState([]);

  const [populationData, setPopulationData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState(defaultFilters);

  const [selectedCountryData, setSelectedCountryData] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');

  const [showCountryList, setShowCountryList] = useState(true);

  const [selectedLetter, setSelectedLetter] = useState(null);

  const [selectedCountries, setSelectedCountries] = useState([]);

  const [mergeCharts, setMergeCharts] = useState(false);

  const [selectedYears, setSelectedYears] = useState([]);

  const handleYearSelect = (year) => {
    // Toggle the selected year
    setSelectedYears((prevSelectedYears) =>
      prevSelectedYears.includes(year)
        ? prevSelectedYears.filter((selectedYear) => selectedYear !== year)
        : [...prevSelectedYears, year]
    );
  };

  function groupDataByDecades(data) {
    const groupedData = {};
    data.forEach((item) => {
      const year = parseInt(item.Year, 10);
      const decade = Math.floor(year / 10) * 10;
      if (!groupedData[decade]) {
        groupedData[decade] = [];
      }
      groupedData[decade].push(item);
    });
    return groupedData;
  }

  const getPopulationYears = (data) => {
    if (!data) return []; // Check if data is null before proceeding

    const yearData = [];

    data.forEach((item) => {
      const year = parseInt(item.Year, 10);
      if (!yearData.includes(year)) {
        yearData.push(year);
      }
    });

    yearData.sort((a, b) => a - b);

    return yearData;
  };

  const handleCountryClick = (country) => {
    const selectedData = ParticipationData.filter((data) => data['Country or Area'] === country);
    setSelectedCountryData(selectedData);
    setSelectedCountries((prevSelectedCountries) => [
      ...prevSelectedCountries,
      { country, data: selectedData },
    ]);
    setShowCountryList(false);
  };

  const handleGoBack = () => {
    setSelectedCountryData(null);
    setShowCountryList(true);
    setSelectedCountries([]); // Optionally reset the selected countries list as well
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  const handleMergeCharts = () => {
    setMergeCharts((prevMergeCharts) => !prevMergeCharts);
  };

  const countryList = (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <input
        type="text"
        placeholder="Search countries..."
        value={searchQuery}
        onChange={handleSearchChange}
        style={{
          padding: '8px',
          marginBottom: '16px',
          fontSize: '16px',
          borderRadius: '20px',
          outline: 'none',
          border: '1px solid black',
          width: '80%',
          textAlign: 'center',
          backgroundColor: '#f5f5f5',
        }}
      />
      {searchQuery && (
        <Tooltip title="Clear search">
          <IconButton onClick={handleClearSearch} style={{ color: 'red' }}>
            <Iconify icon="bi:x-circle" width={20} height={20} />
          </IconButton>
        </Tooltip>
      )}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
          gap: '16px',
        }}
      >
        {[...new Set(ParticipationData.map((data) => data['Country or Area']))]
          .filter((countryName) => countryName.toLowerCase().includes(searchQuery.toLowerCase()))
          .map((countryName, index) => {
            const color = getColorForCountry(countryName);

            return (
              <button
                key={countryName}
                type="button"
                onClick={() => handleCountryClick(countryName)}
                style={{
                  padding: '8px 16px',
                  background: color,
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  outline: 'none',
                }}
              >
                {countryName}
              </button>
            );
          })}
      </div>
    </div>
  );

  function getColorForCountry(countryName) {
    const hashCode = countryName.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);

    const hue = hashCode % 360;

    return `hsl(${hue}, 70%, 80%)`;
  }

  console.log(ParticipationData);

  const selectedCountryInfo = (
    <div>
      {/* ... (existing code) */}
      {/* Step 2: Add UI for year selection */}
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <strong>Select Years:</strong>{' '}
        {getPopulationYears(selectedCountryData).map((year) => (
          <Button
            key={year}
            variant={selectedYears.includes(year) ? 'contained' : 'outlined'}
            onClick={() => handleYearSelect(year)}
            style={{ marginLeft: '5px' }}
          >
            {year}
          </Button>
        ))}
      </div>
      {mergeCharts ? (
        <Grid xs={12} md={6} lg={8}>
          <AppAreaInstalled
            title="Merged Chart"
            chart={{
              categories: selectedCountries.reduce(
                (acc, country) => acc.concat(country.data.map((item) => item.Year)),
                []
              ),
              series: [
                {
                  year: selectedYears.length ? 'Selected Years' : 'All Years', // Use 'All Years' if no years are selected
                  data: selectedCountries.reduce(
                    (acc, country) =>
                      acc.concat([
                        {
                          name: `${country.country} Participation Pre-Primary Learning`,
                          data: country.data
                            .filter(
                              (item) =>
                                !selectedYears.length ||
                                selectedYears.includes(parseInt(item.Year, 10))
                            )
                            .map((item) => parseFloat(item.Value).toFixed(2)), // toFixed eklenmiş
                        },
                      ]),
                    []
                  ),
                },
              ],
            }}
          />
        </Grid>
      ) : (
        selectedCountries.map((selectedCountry) => (
          <Grid xs={12} md={6} lg={8} key={selectedCountry.country}>
            <AppAreaInstalled
              title={`Selected Country - ${selectedCountry.country}`}
              chart={{
                categories: selectedYears.length
                  ? selectedYears
                  : getPopulationYears(selectedCountry.data), // Use selected years or all years if none are selected
                series: [
                  {
                    year: selectedYears.length ? 'Selected Years' : 'All Years', // Use 'All Years' if no years are selected
                    data: [
                      {
                        name: `${selectedCountry.country} School enrollment, preprimary (% gross)`,
                        data: selectedCountry.data
                          .filter(
                            (item) =>
                              !selectedYears.length ||
                              selectedYears.includes(parseInt(item.Year, 10))
                          )
                          .map((item) => parseFloat(item.Value).toFixed(2)),
                      },
                    ],
                  },
                ],
              }}
            />
          </Grid>
        ))
      )}
    </div>
  );

  useEffect(() => {
    if (ParticipationData.length) {
      setTableData(ParticipationData);
    }
  }, [ParticipationData]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (country, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [country]: value,
      }));
    },
    [table]
  );

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData.filter((row) => row.id !== id);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const fetchPopulationData = async () => {
    try {
      const response = await axios.get(endpoints.countrydata.population);
      return response.data;
    } catch (error) {
      console.error('Popülasyon verilerini çekerken hata oluştu:', error);
      throw error;
    }
  };

  const handleAddCountry = () => {
    setSelectedCountryData(null);
    setShowCountryList(true);
  };

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchPopulationData();
        setPopulationData(data);
        setLoading(false);
      } catch (error) {
        console.error('Verileri çekerken hata oluştu:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading="Participation Pre-Primary Learning"
          links={[{ fullName: '', href: paths.dashboard.root }]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Card>
          {/* ... (existing code) */}
          <Grid xs={12} md={6} lg={8}>
            {/* ... (existing code) */}
          </Grid>
        </Card>
        {/* Conditionally render the list of countries, the selected country data, or the go back button */}
        {showCountryList ? (
          <div>{countryList}</div>
        ) : (
          <div>
            {selectedCountryInfo}
            <Button variant="contained" onClick={handleGoBack} style={{ marginTop: '10px' }}>
              Go Back
            </Button>
            <Button
              variant="contained"
              onClick={handleAddCountry}
              style={{ marginTop: '10px', marginLeft: '10px' }}
            >
              Add Country
            </Button>
            <Button
              variant="contained"
              onClick={handleMergeCharts}
              style={{ marginTop: '10px', marginLeft: '10px' }}
            >
              Merge
            </Button>
          </div>
        )}
      </Container>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const { fullName, country, status, role } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (fullName) {
    inputData = inputData.filter((mock) => {
      const countryMatches =
        mock['Country or Area'].toLowerCase().indexOf(fullName.toLowerCase()) !== -1;
      const yearMatches = mock.Year.toString().indexOf(fullName) !== -1;
      return countryMatches || yearMatches;
    });
  }

  return inputData;
}
