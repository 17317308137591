import { Helmet } from 'react-helmet-async';
import DemandForFamilyPlaningListView from 'src/sections/demandforfamily/view/demandfor-family-view';
import ParticipationListView from 'src/sections/participation/view/participation-list-view';
// sections


// ----------------------------------------------------------------------

export default function FamilyPlanningListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Family Planning List</title>
      </Helmet>

      <DemandForFamilyPlaningListView />
    </>
  );
}
