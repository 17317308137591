import { Helmet } from 'react-helmet-async';
import ConflictTableView from 'src/sections/conflictTable/View/ConflictTableView';
// sections


// ----------------------------------------------------------------------

export default function ConflictTablePage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Conflict Table</title>
      </Helmet>

      <ConflictTableView />
    </>
  );
}
