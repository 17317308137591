import { endpoints } from 'src/utils/axios';
import axios from 'axios';


export async function CountryGetData(countryId, subjectId)
{

  const accessToken = sessionStorage.getItem("accessToken");
  if (accessToken) {
      const URL = `${endpoints.countrydata.population}?countryId=${countryId}&subjectId=${subjectId}`;

      try {
          const res = await axios.get(URL, {
              headers: {
                  'Authorization': `Bearer ${accessToken}`
              }
          });
          console.log(res);
          return res.data;
      } catch (error) {
          console.error("Error fetching data:", error);
          throw error; // İstek sırasında bir hata oluştuğunda yakalayın ve işleyin
      }
  } else {
      // AccessToken yok veya geçersiz
      // Gerekli işlemleri gerçekleştirin
  }
}

export async function CountryGetData2(requestData) {
  // const URL = endpoints.countrydata.population;
  // https://localhost:7180/api/Data/Get?countryId=%204555&subjectId=5545

  const URL = `${endpoints.countrydata.countryDataUrl}`;

     const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error('Data listelerken hata oluştu');
      }

      const data = await response.json();
  
  return data;
}
export async function ImportDataListXmlData(subjectId,file) {

  
  const URL = `${endpoints.countrydata.xmlDataImport}?subjectId=${subjectId}`;

  // https://localhost:7180/api/DataImport/ImportDataList?subjectId=434334
  try {

    const formData = new FormData();
    formData.append('filepatch', file);
    const result = await axios.post(URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });


    // API'den gelen veriyi konsolda görüntüle
    console.log('API Response:', result.data.result);

    return result.data.result;
  } catch (error) {
    // Hata durumlarını ele almak önemlidir
    console.error('Error fetching data:', error);
    throw error; // Hata durumunu çağıran kod bloğuna iletiliyor
  }
}