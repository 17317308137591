import useSWR from 'swr';
import { useMemo } from 'react';
import axios,{endpoints,fetcher} from 'src/utils/axios';


export function UrbanPopulationGetData() {
  const URL = endpoints.countrydata.urbanpopulation;
  
  const { data, isLoading, error, isValidating } = useSWR([URL], fetcher);

  const memoizedValue = useMemo(
    () => ({
        UrbanPopulationData: data || [],
        UrbanPopulationDataLoading: isLoading,
        UrbanPopulationDataError: error,
        UrbanPopulationDataValidating: isValidating,
        UrbanPopulationDataEmpty: !isLoading && data?.length < 1,
    }),
    [data, error, isLoading, isValidating]
  );
  return memoizedValue;
}
