import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// _mock
import { USER_STATUS_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSelect, RHFTextField, RHFAutocomplete } from 'src/components/hook-form';
import { Grid, Typography } from '@mui/material';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';

// ----------------------------------------------------------------------

export default function ConflictTableEditForm({
  currentUser,
  countryDescription,
  subjectDescription,
  year,
  existingSourceDataDescription,
  existingValue, // Burada existingValue'yi alıyoruz
  incomingSourceDataDescription,
  incomingValue,
  isResulted,
  id,
  open,
  onClose,
}) {
  const { enqueueSnackbar } = useSnackbar();
  // console.log(incomingSourceDataDescription);
  // console.log(incomingValue);
  // console.log(existingValue);
  // console.log(existingSourceDataDescription);
  // console.log(id);
  // console.log(countryDescription);
  // console.log(year);

  const methods = useForm({});

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = handleSubmit(async (data) => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken) {
    try {
      if (!clickedValue) {
        enqueueSnackbar('Please choose a result', { variant: 'error' });
        return;
      }
      // Verileri hazırla
      const apiData = {
        conflictedDataId: id,
        selectedConflictedDataType: clickedValue === 'existing' ? 0 : 1,
        // Diğer verileri de ekle
        // ...
      };

      // API isteği
      const response = await fetch('https://backend.ocdt.berkuk.xyz/api/ConflictData/Approvement', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
           'Authorization': `Bearer ${accessToken}`
          // Gerekirse diğer başlıkları ekleyin
        },
        
        body: JSON.stringify(apiData),
      });

      if (response.ok) {
        // Başarılı ise işlemleri gerçekleştir
        await new Promise((resolve) => setTimeout(resolve, 500));
        reset();
        onClose();
        enqueueSnackbar('Update success!');
        console.info('DATA', data);
        window.location.reload();
      } else {
        // Hata durumunu ele al
        console.error('API Error:', response.statusText);
        // Hata mesajını göstermek için enqueueSnackbar veya başka bir yöntem kullanabilirsiniz.
      }
    } catch (error) {
      console.error(error);
    }
  }
  });

  const handleIncomingValueClick = (value) => {
    // Bu fonksiyon ile incomingValue üzerine tıklandığında yapılacak işlemleri tanımlayabilirsiniz.
    console.log(`Incoming Value Clicked: ${value}`);
  };

  const handleExistingValueClick = (value) => {
    // Bu fonksiyon ile existingValue üzerine tıklandığında yapılacak işlemleri tanımlayabilirsiniz.
    console.log(`Existing Value Clicked: ${value}`);
  };

  const [clickedValue, setClickedValue] = useState(null);

  const handleValueClick = (value) => {
    // Toggle the state between 'incoming' and 'existing' when a button is clicked
    setClickedValue((prevValue) => (prevValue === value ? null : value));
    console.log(`${value} Value Clicked: ${value}`);
  };

  const [hoveredValue, setHoveredValue] = useState(null);

  const router = useRouter();

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { maxWidth: 850 },
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>
          <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 4 }}>
            Conflict Update
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              {countryDescription && (
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ marginBottom: '12px' }}
                >
                  <span style={{ color: 'black' }}>Country:</span>{' '}
                  {countryDescription.charAt(0).toUpperCase() + countryDescription.slice(1)}
                </Typography>
              )}
              {year && (
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  style={{ marginBottom: '12px' }}
                >
                  <span style={{ color: 'black' }}>Year:</span> {year}
                </Typography>
              )}
              {subjectDescription && (
                <Typography variant="subtitle1" color="textSecondary">
                  <span style={{ color: 'black' }}>Subject:</span> {subjectDescription}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              {incomingSourceDataDescription && (
                <Typography variant="subtitle1" color="textSecondary">
                  <span style={{ color: 'black' }}>Incoming Source:</span>{' '}
                  {incomingSourceDataDescription.replace(/^https?:\/\//, '')}
                </Typography>
              )}
              {existingSourceDataDescription && (
                <Typography variant="subtitle1" color="textSecondary">
                  <span style={{ color: 'black' }}>Existing Source:</span>{' '}
                  {existingSourceDataDescription.replace(/^https?:\/\//, '')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={() => handleValueClick('existing')}
                onMouseEnter={() => setHoveredValue('existing')}
                onMouseLeave={() => setHoveredValue(null)}
              >
                {existingValue && (
                  <Box>
                    <Alert
                      severity={clickedValue === 'existing' ? 'success' : 'info'}
                      sx={{ backgroundColor: hoveredValue === 'existing' && 'lightgray' }}
                    >
                      <strong>Existing Value: </strong>
                      <Typography component="span">{existingValue}</Typography>
                    </Alert>
                  </Box>
                )}
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                onClick={() => handleValueClick('incoming')}
                onMouseEnter={() => setHoveredValue('incoming')}
                onMouseLeave={() => setHoveredValue(null)}
              >
                {incomingValue && (
                  <Box>
                    <Alert
                      severity={clickedValue === 'incoming' ? 'success' : 'info'}
                      sx={{ backgroundColor: hoveredValue === 'incoming' && 'lightgray' }}
                    >
                      <strong>Incoming Value: </strong>
                      <Typography component="span">{incomingValue}</Typography>
                    </Alert>
                  </Box>
                )}
              </Button>
            </Grid>
          </Grid>

          {/* Diğer form alanları burada olacak */}
        </DialogContent>

        <DialogActions>
          {/* Uyarı mesajı ve tıklanabilir bağlantı */}
          <Alert severity="warning" sx={{ mr: 42 }}>
            {' '}
            {/* ml: 1, mr: 1 ile sola kaydırma yapabilirsiniz */}
            Click to enter manual data{' '}
            <Typography
              component="span"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                // manualdataentry sayfasına yönlendirme işlemi
                router.push(paths.dashboard.manualdataentry);
              }}
            >
              click here
            </Typography>
          </Alert>
          <Button onClick={onClose} variant="contained" color="error">
            Cancel
          </Button>
          <Button onClick={onSubmit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

ConflictTableEditForm.propTypes = {
  currentUser: PropTypes.object,
  countryDescription: PropTypes.string,
  subjectDescription: PropTypes.string,
  year: PropTypes.number,
  existingSourceDataDescription: PropTypes.string,
  existingValue: PropTypes.string,
  incomingSourceDataDescription: PropTypes.string,
  incomingValue: PropTypes.string,
  isResulted: PropTypes.bool,
  id: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
