import useSWR from 'swr';
import { useMemo } from 'react';
import axios,{endpoints,fetcher} from 'src/utils/axios';


export function ParticipationGetData() {
  const URL = endpoints.countrydata.participation;
  
  const { data, isLoading, error, isValidating } = useSWR([URL], fetcher);

  const memoizedValue = useMemo(
    () => ({
      ParticipationData: data || [],
      ParticipationDataLoading: isLoading,
      ParticipationDataError: error,
      ParticipationDataValidating: isValidating,
      ParticipationDataEmpty: !isLoading && data?.length < 1,
    }),
    [data, error, isLoading, isValidating]
  );
  return memoizedValue;
}
