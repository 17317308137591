import { Helmet } from 'react-helmet-async';
import ParticipationListView from 'src/sections/participation/view/participation-list-view';
// sections


// ----------------------------------------------------------------------

export default function ParticipationListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Participation List</title>
      </Helmet>

      <ParticipationListView />
    </>
  );
}
