import useSWR from 'swr';
import { useMemo } from 'react';
// utils
import { fetcher, endpoints } from 'src/utils/axios';
import axios from 'axios';

export function ManualDataEntryData() {
  const URL = endpoints.countrydata;

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      usageDataRecords: data || [],
      usageDataRecordstLoading: isLoading,
      usageDataRecordsError: error,
      usageDataRecordsValidating: isValidating,
      usageDataRecordsEmpty: !isLoading && data?.result?.totalCount < 1,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}
export async function ManualEntry(countryId, subjectId, value, year) {
  // const URL = endpoints.countrydata.manualdataentry ;

  const accessToken = sessionStorage.getItem("accessToken");
  if (accessToken) {
  const URL = `${endpoints.countrydata.manualdataentry}?subjectId=${subjectId}`;

    // https://localhost:7180/api/DataImport/ImportDataList?subjectId=434334
  try {
    const result = await axios.post(URL, [
      { sourceDataId: '000000000000000000000000', year, value, countryId },
    ],{
      headers: {
          'Authorization': `Bearer ${accessToken}`
      }
  });

    // API'den gelen veriyi konsolda görüntüle
    console.log('API Response:', result.data);
  
    return { message: result.data };
  } catch (error) {
    // Hata durumlarını ele almak önemlidir
    console.error('Error fetching data:', error);
    throw error; // Hata durumunu çağıran kod bloğuna iletiliyor
  }
}
else {
  // AccessToken yok veya geçersiz
  // Gerekli işlemleri gerçekleştirin
}
}
