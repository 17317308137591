import { useCallback, useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { SubjectGetData } from 'src/api/subject';
import Link from 'src/theme/overrides/components/link';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  roles: icon('ic_lock'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const { SubjectData } = SubjectGetData();
  const createMenuItems = useCallback(
    (items, parentPath = '') =>
      items.map((item, index) => {
        const fullPath = `${parentPath}/${item.id}`;
        const path = `${paths.dashboard.data}/${item.id}`;

        const customPath = (() => {
          if (item.id === '65815de3334e57824a1bb094') {
            return paths.dashboard.manualdataentry;
          }
        
          if (item.id === '6581916d186bf5326df21dd3') {
            return paths.dashboard.conflicttable;
          }
        
          return path;
        })();           
        return {
          title: t(item.description),
          path: customPath,
          icon: ICONS.folder,
          children: item.childs ? createMenuItems(item.childs, fullPath) : undefined,
        };
      }),
    [t]
  );

  const renderMenuItems = (items) => (
    items.map((item, index) => (
      <div key={index}>
        <Link to={item.path}>
          <div>
            {item.icon}
            {item.title}
          </div>
        </Link>
        {item.children && renderMenuItems(item.children)}
      </div>
    ))
  );

  const data = useMemo(() => {
    if (!SubjectData) return [];

    return SubjectData.map((category) => ({
      subheader: <span style={{ fontSize: '13px' }}>{t(category.description)}</span>,
      items: createMenuItems(category.childs),
    }));
  }, [t, SubjectData, createMenuItems]);

  return data;
}
