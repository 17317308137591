import useSWR from 'swr';
import { useMemo } from 'react';
import axios,{endpoints,fetcher} from 'src/utils/axios';


export function GetCountryListApiResponseData() {
  const URL = endpoints.countrydata.country;
  
  const { data, isLoading, error, isValidating } = useSWR([URL], fetcher);

  const memoizedValue = useMemo(
    () => ({
        countryListApiResponseData: data || [],
        countryListApiResponseDataLoading: isLoading,
        countryListApiResponseDataError: error,
        countryListApiResponseDataValidating: isValidating,
        countryListApiResponseDataEmpty: !isLoading && data?.length < 1,
    }),
    [data, error, isLoading, isValidating]
  );
  return memoizedValue;
}
