import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect } from 'react';
// @mui
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/system/Unstable_Grid/Grid';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'react-router';

// _mock
import { _userList, _roles, USER_STATUS_OPTIONS, _metaDataType } from 'src/_mock';
import { _metaDataTypes } from 'src/_mock/_metaData';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// api

// components
import Iconify from 'src/components/iconify';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import AppAreaInstalled from 'src/sections/overview/app/app-area-installed';
import { CountryGetData } from 'src/api/country-data';
import { GetCountryListApiResponseData } from 'src/api/country';
import FileManagerNewFolderDialog from 'src/sections/file-manager/file-manager-new-folder-dialog';
import ImportManagerNewFolderDialog from 'src/sections/ımport/ImportManagerNewFolderDialog';

import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
  TableSkeleton,
} from 'src/components/table';
import { ConflictGets } from 'src/api/conflictdatatable';
import UserTableRow from 'src/sections/user/user-table-row';
import UserTableToolbar from 'src/sections/user/user-table-toolbar';
import Scrollbar from 'src/components/scrollbar';
import PopulationTableRow from '../population-table-row';


const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

function PopulationListView() {
  const table = useTable();
  const { countryListApiResponseData, countryListApiResponseDataLoading } =
    GetCountryListApiResponseData();
  const [tableData, setTableData] = useState(_userList);

  const [searchQuery, setSearchQuery] = useState('');
  const [showCountryList, setShowCountryList] = useState(true);

  const upload = useBoolean();
  const ımport = useBoolean();
  const { id } = useParams();
  const [selectedCountryId, setSelectedCountryId] = useState(null);

  const [yearList, setYearList] = useState([]);

  const [selectedCountryIdList, setSelectedCountryIdList] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [apiResponseChartDataList, setApiResponseChartDataList] = useState([]);
  const [chartDataList, setChartDataList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [chartTitleText, setChartTitleText] = useState(null);
  const [chartSubheaderText, setChartSubheaderText] = useState(null);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const confirm = useBoolean();

  // STEP1  ÜLKE SEÇİMİ YAPILIYOR.  SEÇİLEN ÜLKELER selectedCountryIdList e EKLENİYOR.
  // seçilen countryidleri listeye ekliyoruz.
  function onClickCountry(countryId) {
    setSelectedCountryId(countryId);
    const isIdNotPresent =
      selectedCountryIdList.find((selected) => selected === countryId) === undefined;
    if (isIdNotPresent) {
      setSelectedCountryIdList([...selectedCountryIdList, countryId]);
    }
    setSelectedCountryId(countryId);
    setShowCountryList(false);
  }

  /* STEP2 SEÇİLMİŞ ÜLKELERE GÖRE selectedCountryIdList teki IDLERE APIDEN ülkerin kayıtlarını
   apiResponseChartDataList 'e yazıyoruz.
   selectedCountryIdList değiştiğinde tetikleniyor. */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = selectedCountryIdList.map((item) => CountryGetData(item, id));
        const resolvedDataArray = await Promise.all(data);
        setApiResponseChartDataList(resolvedDataArray);
        getdataYearList(resolvedDataArray);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedCountryIdList, id]);

  // API'DEN DÖNEN KAYITLARIN İÇERİSİNDEKİ KAYITLARIN YILLARININ LİSTESİNİ  yearList E YAZIYORUZ
  function getdataYearList(resolvedDataArray) {
    const data = Array.from(
      new Set(
        resolvedDataArray
          .map((x) => x[0])
          .filter((a) => a !== undefined)
          .flatMap((item) => item.dataDetail.map((yearData) => yearData.year))
      )
    );
    setYearList(data);
  }
  // selectedyears DEĞİŞTİĞİNDE CHART'IN categories alanı alt taraftaki yıllar güncellenecek
  //  useEffect(() => {
  //   debugger
  //   // selectedYears değiştiğinde categories'i güncelle
  //   setCategories(selectedYears.length > 0 ? selectedYears :([...new Set(chartDataList.map(x => x.years).flat())]?yearList:[]));
  // }, []);

  // selectedCountryIdList değiştiğinde tetikleniyor. chart için data oluşturuluyor
  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataList1 = apiResponseChartDataList.map((x) => x[0]).filter((a) => a !== undefined);
        const dataList = dataList1.map((item) => ({
          countryId: item.countryId,
          countryDescription: item.countryDescription,
          subjectDescription: item.subjectDescription,
          datas: { data: item.dataDetail.map((data) => ({ value: data.value, year: data.year })) },
        }));
        console.log(dataList);
        setChartDataList(dataList);
        setChartTitleText(dataList.map((x) => x.subjectDescription)[0]);
        setChartSubheaderText(dataList.map((x) => x.countryDescription).join(', ')); // Modified line
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [apiResponseChartDataList]);

  const handleGoBack = () => {
    setSelectedCountryIdList([]);
    setChartDataList([]);
    setSelectedYears([]);
    setShowCountryList(true);
  };
  const handleGoBackForAddCountry = () => {
    setShowCountryList(true);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  // const handleMergeCharts = () => {
  //   setMergeCharts((prevMergeCharts) => !prevMergeCharts);
  // };

  const handleImport = () => {
    // Excel dosyasının bulunduğu yol
    const templatePath = '/excel_templates/DataManagment_Import_Data.xlsx';

    // Aşağıdaki kod, belirtilen yoldaki Excel dosyasını indirmek için kullanılır
    const link = document.createElement('a');
    link.href = process.env.PUBLIC_URL + templatePath;
    link.download = 'DataManagment_Import_Data.xlsx';
    link.click();
  };

  const countryList = (
    <div style={{ textAlign: 'center', margin: '20px' }}>
      <input
        type="text"
        placeholder="Search countries..."
        value={searchQuery}
        onChange={handleSearchChange}
        style={{
          padding: '8px',
          marginBottom: '16px',
          fontSize: '16px',
          borderRadius: '20px',
          outline: 'none',
          border: '1px solid black',
          width: '300px',
          textAlign: 'left',
          backgroundColor: '#f5f5f5',
          marginRight: '550px',
        }}
      />
      {searchQuery && (
        <Tooltip title="Clear search">
          <IconButton
            onClick={handleClearSearch}
            style={{ color: 'red', position: 'relative', left: '-585px' }}
          >
            <Iconify icon="bi:x-circle" width={20} height={20} />
          </IconButton>
        </Tooltip>
      )}
      <Button
        variant="contained"
        onClick={handleImport}
        startIcon={<Iconify icon="solar:import-outline" />}
        style={{
          marginTop: '10px',
          marginLeft: '275px',
          backgroundColor: '#28a745',
          color: '#fff',
        }}
      >
        Export
      </Button>
      <Button
        variant="contained"
        onClick={ımport.onTrue}
        startIcon={<Iconify icon="solar:export-outline" />}
        style={{ marginTop: '10px', marginLeft: '10px', backgroundColor: '#3d9970' }}
      >
        Import
      </Button>
      <Button
        variant="contained"
        startIcon={<Iconify icon="eva:cloud-upload-fill" />}
        onClick={upload.onTrue}
        style={{ marginTop: '10px', marginLeft: '10px' }}
      >
        Upload XML
      </Button>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
          gap: '16px',
        }}
      >
        {[...new Set(countryListApiResponseData.map((data) => data.description))]
          .filter((countryName) => countryName.toLowerCase().includes(searchQuery.toLowerCase()))
          .map((countryName, index) => {
            const color = getColorForCountry(countryName);
            const findCountry = countryListApiResponseData.find(
              (data) => data.description === countryName
            );
            return (
              <button
                key={countryName}
                type="button"
                onClick={() => onClickCountry(findCountry.id)}
                style={{
                  padding: '8px 16px',
                  background: color,
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  outline: 'none',
                }}
              >
                {countryName}
              </button>
            );
          })}
      </div>
    </div>
  );

  function getColorForCountry(countryName) {
    const hashCode = countryName.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);

    const hue = hashCode % 360;

    return `hsl(${hue}, 70%, 80%)`;
  }

  function handleYearSelect(year) {
    // Eğer year zaten selectedYears içinde varsa, onu kaldır
    if (selectedYears.includes(year)) {
      setSelectedYears((prevSelectedYears) =>
        prevSelectedYears.filter((selectedYear) => selectedYear !== year)
      );
    } else {
      // Yoksa, year'ı eklemeyi dene
      setSelectedYears((prevSelectedYears) =>
        prevSelectedYears.includes(year) ? prevSelectedYears : [...prevSelectedYears, year]
      );
    }
  }
  function deselectYear() {
    setSelectedYears([]);
  }
  const chartYears = (
    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
      {/* <strong>Select Years:</strong>{' '} */}
      {yearList.map((year) => (
        <Button
          key={year}
          variant={selectedYears.includes(year) ? 'contained' : 'outlined'}
          onClick={() => handleYearSelect(year)}
          style={{ margin: '5px' }}
        >
          {year}
        </Button>
      ))}
      <Button
        // key={year}
        // variant={selectedYears.includes(year) ? 'contained' : 'outlined'}
        onClick={() => deselectYear()}
        style={{ margin: '5px', backgroundColor: '#FCBF49' }}
      >
        Deselect All
      </Button>
    </div>
  );
  const charttest = (
    <div>
      <Grid xs={12} md={6} lg={8}>
        <AppAreaInstalled
          title={chartTitleText}
          subheader={chartSubheaderText}
          chart={{
            categories:
              selectedYears.length > 0
                ? selectedYears.sort()
                : yearList
                    .sort()
                    .filter((x) => x.includes(selectedYears))
                    .sort(),
            series: [
              {
                year: selectedYears.length ? 'Selected Years' : 'All Years',
                data: chartDataList
                  .filter((item) =>
                    selectedYears.length > 0
                      ? item.datas.data.some((data) => selectedYears.includes(data.year))
                      : true
                  )
                  .map((item) => ({
                    name: item.countryDescription,
                    data:
                      selectedYears.length > 0
                        ? item.datas.data
                            .filter((data) => selectedYears.includes(data.year))
                            .map((data) => data.value)
                        : item.datas.data.map((data) => data.value),
                  })),

                // .filter(item => selectedYears.length ? item.years.some(year => selectedYears.includes(year)) : true)
                // .map(item => ({ name: item.name, data: item.data }))
              },
            ],
          }}
        />
      </Grid>
    </div>
  );

  const subjectDescription = '';

  return (
    <>
      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={subjectDescription}
          links={[{ fullName: '', href: paths.dashboard.root }]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Card>
          {/* ... (existing code) */}

          <Grid xs={12} md={6} lg={8}>
            {/* ... (existing code) */}
          </Grid>
        </Card>
        <FileManagerNewFolderDialog
          open={upload.value}
          onClose={upload.onFalse}
          countryListData={countryListApiResponseData}
        />
        <ImportManagerNewFolderDialog open={ımport.value} onClose={ımport.onFalse} />
        {/* Conditionally render the list of countries, the selected country data, or the go back button */}
        {showCountryList ? (
          <div>{countryList}</div>
        ) : (
          <div>
            {chartYears}
            {charttest}

            <>
            </>
            <Button variant="contained" onClick={handleGoBack} style={{ marginTop: '10px' }}>
              Go Back
            </Button>
            <Button
              variant="contained"
              onClick={handleGoBackForAddCountry}
              style={{ marginTop: '10px', marginLeft: '10px', backgroundColor: ' #588157' }}
            >
              Add Country
            </Button>
            {/* <Button
              variant="contained"
              onClick={handleMergeCharts}
              style={{ marginTop: '10px', marginLeft: '10px' }}
            >
              Merge
            </Button> */}
          </div>
        )}
      </Container>
    </>
  );
}
function applyFilter({ inputData, comparator, filters }) {
  const {
    name,
    status,
    role,
    subjectDescription,
    countryDescription,
    incomingSourceDataDescription,
    isResulted,
  } = filters;

  let filteredData = inputData;

  if (name) {
    filteredData = filteredData.filter((user) =>
      user.name.toLowerCase().includes(name.toLowerCase())
    );
  }

  if (status !== 'all') {
    filteredData = filteredData.filter((user) => user.status === status);
  }

  if (role.length) {
    filteredData = filteredData.filter((user) => role.includes(user.role));
  }

  if (subjectDescription && subjectDescription.length) {
    filteredData = filteredData.filter((user) =>
      subjectDescription.includes(user.subjectDescription)
    );
  }

  if (countryDescription && countryDescription.length) {
    filteredData = filteredData.filter((user) =>
      countryDescription.includes(user.countryDescription)
    );
  }

  if (incomingSourceDataDescription && incomingSourceDataDescription.length) {
    filteredData = filteredData.filter((user) =>
      incomingSourceDataDescription.includes(user.incomingSourceDataDescription)
    );
  }

  if (isResulted && isResulted.length) {
    filteredData = filteredData.filter((user) => isResulted.includes(user.isResulted));
  }

  return filteredData;
}

export default PopulationListView;
