import useSWR from 'swr';
import { useMemo } from 'react';
import axios,{endpoints,fetcher} from 'src/utils/axios';


export function ConflictGets() {
  const URL = endpoints.countrydata.conflict;
  
  const { data, isLoading, error, isValidating } = useSWR([URL], fetcher);

  const memoizedValue = useMemo(
    () => ({
        ConflictGetData: data || [],
        ConflictGetDataLoading: isLoading,
        ConflictGetDataError: error,
        ConflictGetDataValidating: isValidating,
        ConflictGetDataEmpty: !isLoading && data?.length < 1,
    }),
    [data, error, isLoading, isValidating]
  );
  return memoizedValue;
}
