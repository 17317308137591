import { Helmet } from 'react-helmet-async';
import DemandForFamilyPlaningListView from 'src/sections/demandforfamily/view/demandfor-family-view';
import ManualDataEntryView from 'src/sections/manueldataentry/view/ManualDataEntryView';
import ParticipationListView from 'src/sections/participation/view/participation-list-view';
// sections


// ----------------------------------------------------------------------

export default function ManuelDataEntryPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: ManuelDataEntry</title>
      </Helmet>

      <ManualDataEntryView />
    </>
  );
}
