import useSWR from 'swr';
import { useMemo } from 'react';
import axios,{endpoints,fetcher} from 'src/utils/axios';


export function MaternalMortalityGetData() {
  const URL = endpoints.countrydata.maternalmortality;
  
  const { data, isLoading, error, isValidating } = useSWR([URL], fetcher);

  const memoizedValue = useMemo(
    () => ({
        MaternalMortalityData: data || [],
        MaternalMortalityDataLoading: isLoading,
        MaternalMortalityDataError: error,
        MaternalMortalityDataValidating: isValidating,
        MaternalMortalityDataEmpty: !isLoading && data?.length < 1,
    }),
    [data, error, isLoading, isValidating]
  );
  return memoizedValue;
}
