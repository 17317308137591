import { Helmet } from 'react-helmet-async';
import DemandForFamilyPlaningListView from 'src/sections/demandforfamily/view/demandfor-family-view';
import ParticipationListView from 'src/sections/participation/view/participation-list-view';
import UrbanPopulationListView from 'src/sections/urban-population/view/urban-population-view';
// sections

// ----------------------------------------------------------------------

export default function UrbanPopulationListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Urban Population List</title>
      </Helmet>

      <UrbanPopulationListView />
    </>
  );
}
